var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[(_vm.show && _vm.$vuetify.breakpoint.smAndDown)?_c('v-container',{staticClass:"py-0 submenu",class:{
      'background-black': _vm.appBarChange == 0,
      'background-white': _vm.appBarChange == 1,
    },attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center py-10",attrs:{"cols":"auto"}},[(_vm.appBarChange == 0)?[_c('router-link',{staticClass:"menu-app-item d-inline-block rewrite-font",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/menu"}},[_vm._v(_vm._s(_vm.$t("btnMenu")))]),_c('router-link',{staticClass:"menu-app-item d-inline-block rewrite-font px-4",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/contacto"}},[_vm._v(_vm._s(_vm.$t("btnContact")))])]:[_c('router-link',{staticClass:"menu-app-item d-block rewrite-font",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("btnHome")))])],_c('div',{staticClass:"pt-7"},[_c('span',{staticClass:"active-lang",class:{ 'opacity-custom': this.$i18n.locale == 'en' },on:{"click":function($event){return _vm.setLocale('en')}}},[_vm._v("ENG")]),_c('span',{staticClass:"px-4"},[_vm._v("-")]),_c('span',{staticClass:"active-lang",class:{ 'opacity-custom': this.$i18n.locale == 'es' },on:{"click":function($event){return _vm.setLocale('es')}}},[_vm._v("ESP")])])],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }