<template>
  <div class="home">
    <v-dialog
      v-model="showIntro"
      persistent
      fullscreen
      transition="fade-transition"
      overlay-color="black"
    >
      <template v-if="renderVideos">
        <video
          :src="
            'https://srtanakacms.actstudio.xyz/storage/uploads' +
            videosSingleton.video1.path
          "
          muted
          autoplay
          style="height: 100vh; width: 100%; object-fit: cover"
          playsinline
          loop
          class="d-none d-sm-block"
        ></video>

        <video
          :src="
            'https://srtanakacms.actstudio.xyz/storage/uploads' +
            videosSingleton.video1_mobile.path
          "
          muted
          autoplay
          style="height: 100vh; width: 100%; object-fit: contain"
          playsinline
          loop
          class="d-sm-none"
        ></video>
      </template>
    </v-dialog>

    <template v-if="showIntro == false">
      <div class="bg-1">
        <div class="bg-2"></div>
      </div>
      <v-container style="position: relative; z-index: 1">
        <v-row
          align="center"
          justify="center"
          style="height: calc(100vh - 100px)"
        >
          <v-col cols="12" md="6" lg="6" xl="6" class="pl-0">
            <div style="padding-bottom: 0px">
              <div class="d-none d-md-block">
                <br /><br /><br /><br /><br /><br /><br /><br /><br />
              </div>

              <div class="d-md-none"><br /><br /></div>

              <div class="pl-12 pl-md-0 ml-12 ml-md-0">
                <span class="d-block about-text">SEÑOR TANAKA 田中氏</span>
                <span class="d-block about-text">{{
                  $t("firstWordIntro")
                }}</span>
                <span class="d-block about-text">ROCK.</span>
                <span class="d-block about-text">{{
                  $t("secondWordIntro")
                }}</span>
                <span class="d-block about-text"
                  >{{ $t("thirdWordIntro") }} {{ $t("fourthWordIntro") }}</span
                >
                <span class="d-block about-text">時代を超越した</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pb-12" style="position: relative; z-index: 1">
        <v-row>
          <v-col cols="12">
            <span class="platillos-title uppercase">{{
              $t("titleMenuHome")
            }}</span>
          </v-col>
          <v-col cols="12">
            <hr />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <template v-for="(platillo, p) in platillos">
              <v-tooltip
                top
                v-bind:key="'product-' + p"
                content-class="tooltip-custom"
              >
                <template v-slot:activator="{ on }">
                  <span
                    class="home-product uppercase cursor-pointer"
                    @mouseover="activeProduct = p"
                    @mouseout="activeProduct = -1"
                    v-bind:class="{
                      'transparent--':
                        activeProduct != p && activeProduct != -1,
                    }"
                    v-on="on"
                    >{{ platillo.name }} {{ platillo.kanji
                    }}<template v-if="p < platillos.length - 1">, </template>
                  </span>
                </template>

                <template v-if="platillo.imagen != null">
                  <v-img
                    :src="
                      'https://srtanakacms.actstudio.xyz/' +
                      platillo.imagen.path
                    "
                    class="mx-auto img-platillos-home"
                    eager
                  ></v-img>
                </template>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>

        <v-row class="my-sm-12 py-12">
          <v-col class="text-center">
            <v-btn
              class="btn-menu outlined"
              outlined
              color="white"
              to="/menu"
              tile
            >
              <span class="subtitles-app-and-buttons rewrite-font">
                {{ $t("btnMenuText") }} / Menyū メニュー
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row id="temporada">
          <v-col class="pa-0 pa-md-3">
            <v-carousel
              style="max-width: 1161px"
              class="mx-md-auto carousel-temp"
              cycle
              hide-delimiters
              v-model="carouselTemp"
            >
              <template v-for="(destacado, d) in destacados">
                <v-carousel-item
                  :key="'dest-' + d"
                  reverse-transition="fade-c-transition"
                  transition="fade-c-transition"
                >
                  <v-container class="pa-0 pa-md-3">
                    <div
                      class="mx-md-auto home-width-img"
                      :style="
                        'background-image: url(http://srtanakacms.actstudio.xyz' +
                        destacado.cover.path +
                        ');'
                      "
                    >
                      <v-row
                        justify-md="end"
                        justify="center"
                        class="pb-6 padding-top-custom d-none d-md-flex"
                      >
                        <v-col cols="auto" class="code-title-carousel">
                          <div class="pb-md-12">
                            <span
                              class="
                                d-block d-md-inline-block
                                px-2
                                titles-app
                                rewrite-font
                                uppercase
                                white--background
                                black--text
                              "
                              >{{ destacado.titulo }}</span
                            >
                            <div
                              class="d-block px-2 mt-2 white--background"
                              style="width: fit-content"
                            >
                              <span class="titles-app uppercase black--text">{{
                                destacado.ingredienteDestacado
                              }}</span>
                              <span
                                class="titles-app rewrite-font black--text"
                                >{{ destacado.ingredienteKanji }}</span
                              >
                            </div>
                          </div>
                          <v-img
                            :src="
                              'http://srtanakacms.actstudio.xyz/' +
                              destacado.codigoBarras.path
                            "
                            class="mt-6 mt-sm-12 bar-code-first"
                            eager
                          ></v-img>
                        </v-col>
                      </v-row>

                      <!-- tabla en desk pendiente -->
                      <div class="home-width-img__table">
                        <v-row justify="end" class="d-none d-md-flex">
                          <v-col
                            cols="auto"
                            class="px-6 py-6 descripciones-col"
                          >
                            <div style="line-height: 1.2">
                              <span class="d-block titles-app rewrite-font">{{
                                destacado.descripcionKanji
                              }}</span>
                              <span class="d-block">{{
                                destacado.descripcionPlato
                              }}</span>
                            </div>
                          </v-col>

                          <v-col
                            cols="auto"
                            class="d-none d-md-block"
                            style="
                              width: 209px;
                              border: 1px solid #ffffff;
                              border-bottom: 0px;
                            "
                          ></v-col>
                        </v-row>
                        <v-row justify="end" class="d-none d-md-flex">
                          <v-col
                            cols="auto"
                            class="px-6 py-3 texto-destacado-col"
                          >
                            <span
                              class="nimbus titles-app rewrite-font"
                              style="line-height: 1.2"
                              >{{ destacado.textoDestacado }}</span
                            >
                          </v-col>
                          <v-col cols="auto" class="bar-code">
                            <v-img
                              :src="
                                'http://srtanakacms.actstudio.xyz/' +
                                destacado.codigoBarras.path
                              "
                              class="mx-auto bar-code__img"
                              eager
                            ></v-img>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- /tabla en desk -->
                    </div>
                  </v-container>
                </v-carousel-item>
              </template>
            </v-carousel>

            <!--  -->
            <v-row
              justify="center"
              no-gutters
              class="class-mobile-position d-md-none"
              v-for="(destacado, d3) in destacados"
              v-bind:key="'dest-' + d3"
              v-show="d3 == carouselTemp"
            >
              <v-col cols="auto" class="code-title-carousel">
                <v-img
                  :src="
                    'http://srtanakacms.actstudio.xyz/' +
                    destacado.codigoBarras.path
                  "
                  class="bar-code-first ml-auto"
                  eager
                ></v-img>
                <div class="pb-md-12 mt-6 mt-sm-12">
                  <span
                    class="
                      d-block d-md-inline-block
                      px-2
                      titles-app
                      rewrite-font-4
                      uppercase
                      white--background
                      black--text
                    "
                    >{{ destacado.titulo }}</span
                  >
                  <div
                    class="
                      d-block
                      mx-auto
                      d-md-inline-block
                      px-2
                      mt-2
                      white--background
                    "
                    style="width: fit-content"
                  >
                    <span
                      class="titles-app rewrite-font uppercase black--text"
                      >{{ destacado.ingredienteDestacado }}</span
                    >
                    <span class="titles-app rewrite-font black--text">{{
                      destacado.ingredienteKanji
                    }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- tabla en tablet vertical para abajo -->
            <v-row no-gutters>
              <v-col
                v-for="(destacado, d2) in destacados"
                v-bind:key="'dest-' + d2"
                v-show="d2 == carouselTemp"
                class="px-8"
              >
                <v-row justify="end" class="d-md-none">
                  <v-col cols="auto" class="px-6 py-6 descripciones-col">
                    <span class="d-block titles-app">{{
                      destacado.descripcionKanji
                    }}</span>
                    <span class="d-block">{{
                      destacado.descripcionPlato
                    }}</span>
                  </v-col>

                  <v-col
                    cols="auto"
                    class="d-none d-md-block"
                    style="
                      width: 209px;
                      border: 1px solid #ffffff;
                      border-bottom: 0px;
                    "
                  ></v-col>
                </v-row>
                <v-row justify="end" class="d-md-none">
                  <v-col cols="auto" class="px-6 py-3 texto-destacado-col">
                    <span class="nimbus titles-app">{{
                      destacado.textoDestacado
                    }}</span>
                  </v-col>
                  <v-col cols="auto" class="bar-code">
                    <v-img
                      :src="
                        'http://srtanakacms.actstudio.xyz/' +
                        destacado.codigoBarras.path
                      "
                      class="mx-auto bar-code__img"
                      eager
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- /tabla en tablet vertical para abajo -->
          </v-col>
        </v-row>

        <v-row id="contacto" class="my-sm-12 py-sm-12 mt-12">
          <v-col class="text-center">
            <span class="d-block titles-app uppercase" style="line-height: 1.2"
              >{{ $t("titleLocationSchedule") }} / <br class="d-sm-none" />
              Basho to sukejūru</span
            >
            <span class="d-block titles-app" style="line-height: 1.2"
              >場所とスケジュール</span
            >
          </v-col>
        </v-row>

        <div class="mx-auto mt-8 mt-md-0" style="max-width: 1161px">
          <!-- sucursal 1 -->
          <v-row>
            <v-col cols="12" md="4" class="text-center text-md-left">
              <span class="d-block uppercase">Plaza Duendes</span>
              <span class="d-block uppercase">Calzada San Pedro Sur 102</span>
              <span class="d-block uppercase">San Pedro Garza García, NL</span>
              <a
                href="https://goo.gl/maps/zX36Tvj9TdAoamFx9"
                class="d-block white--text uppercase pt-3 pb-3"
                target="_blank"
                >{{ $t("btnSeeLocation") }}</a
              >
              <span class="d-block"></span>
              <a
                href="tel:8183352060"
                class="d-block white--text not-text-decoration"
                target="_blank"
                >RSVP. 8183352060</a
              >
            </v-col>

            <v-col cols="12" md="4" class="text-center text-md-left">
              <span class="d-block uppercase">{{ $t("firstDays") }}</span>
              <span class="d-block">13:00 – 22:00 HRS</span>

              <span class="d-block mt-3 uppercase">{{ $t("secondDays") }}</span>
              <span class="d-block">13:00 – 23:00 HRS</span>
            </v-col>

            <v-col
              cols="12"
              md="4"
              align-self="center"
              class="text-center mt-8 mt-md-0"
            >
              <template v-if="datetime.date.dayOfWeek < 6">
                <!-- Entre Lunes y Viernes -->
                <template
                  v-if="datetime.time.hour >= 13 && datetime.time.hour < 22"
                >
                  <v-card
                    :href="homeSingleton.btn_order_here"
                    target="_blank"
                    :ripple="false"
                    elevation="0"
                    color="transparent"
                  >
                    <v-img
                      src="../assets/img/order-here.gif"
                      max-width="215"
                      class="img-open-closed"
                      eager
                    ></v-img>
                  </v-card>
                </template>
                <template v-else>
                  <v-img
                    src="../assets/img/NOWCLOSED-TANAKA.gif"
                    max-width="215"
                    class="img-open-closed"
                    eager
                  ></v-img>
                </template>
              </template>

              <template v-else>
                <!-- Fin de semana -->
                <template
                  v-if="datetime.time.hour >= 13 && datetime.time.hour < 23"
                >
                  <v-card
                    :href="homeSingleton.btn_order_here"
                    target="_blank"
                    :ripple="false"
                    elevation="0"
                    color="transparent"
                  >
                    <v-img
                      src="../assets/img/order-here.gif"
                      max-width="215"
                      class="img-open-closed"
                      eager
                    ></v-img>
                  </v-card>
                </template>
                <template v-else>
                  <v-img
                    src="../assets/img/NOWCLOSED-TANAKA.gif"
                    max-width="215"
                    class="img-open-closed"
                    eager
                  ></v-img>
                </template>
              </template>
            </v-col>
          </v-row>

          <v-row justify="center" justify-md="start" class="mt-8 mt-md-0">
            <v-col cols="4" sm="auto" md="4" class="pr-sm-12 pr-md-3">
              <a
                href="https://www.facebook.com/senortanakamx"
                target="_blank"
                class="text-center d-inline-block white--text"
                style="text-decoration: none"
              >
                <v-icon color="white">mdi-facebook</v-icon>
                <span class="d-block">@senortanakamx</span>
              </a>
            </v-col>

            <v-col cols="4" sm="auto" md="4">
              <a
                href="https://www.instagram.com/senortanakamx"
                target="_blank"
                class="text-center d-inline-block white--text"
                style="text-decoration: none"
              >
                <v-icon color="white">mdi-instagram</v-icon>
                <span class="d-block">@senortanakamx</span>
              </a>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto" md="12" class="mt-3 mt-md-0">
              <v-card
                class="menu-app-item d-inline-block px-4 outlined color-white cursor-pointer"
                target="_blank"
                :href="homeSingleton.btn_delivery"
                :ripple="false"
                color="black"
                elevation="0"
                tile
                >{{ $t("btnHomeService") }}</v-card
              >
            </v-col>
          </v-row>
          <!-- /sucursal 1 -->

          <v-row no-gutters class="d-none d-md-flex" v-if="false">
            <v-col>
              <hr class="hr-custom d-none d-md-block" />
            </v-col>
          </v-row>

          <!-- sucursal 2 -->
          <!-- tablet vertical hacia abajo -->
          <v-row
            class="same-width-hr mb-0 mb-sm-0"
            justify="center"
            v-if="false"
            >
            <!-- v-if="$vuetify.breakpoint.smAndDown" -->
            <v-col cols="auto">
              <hr class="hr-custom-2 d-md-none" />
            </v-col>
            <hr class="hr-custom d-none d-md-block" />
          </v-row>
          <!-- /tablet vertical hacia abajo -->

          <v-row justify="center" v-if="false">
            <v-col cols="12" md="4" class="text-center text-md-left">
              <span class="d-block uppercase">PUERTA DE HIERRO </span>
              <span class="d-block uppercase">AV. REAL ACUEDUCTO #110</span>
              <span class="d-block uppercase">45116, Zapopan, Jalisco.</span>
              <a
                href="https://goo.gl/maps/G3kG5xnQq6fWscCEA"
                class="d-block white--text uppercase pt-3 pb-3"
                target="_blank"
                >{{ $t("btnSeeLocation") }}</a
              >
              <span class="d-block"></span>
              <a
                href="tel:3338351405"
                class="d-block white--text not-text-decoration"
                target="_blank"
                >RSVP. 33 3835 1405</a
              >
            </v-col>

            <v-col cols="12" md="4" class="text-center text-md-left">
              <span class="d-block uppercase">{{
                $t("GuadalajaraDays1")
              }}</span>
              <span class="d-block">13:00 – 01:00 HRS</span>

              <span class="d-block mt-3 uppercase">{{
                $t("GuadalajaraDays2")
              }}</span>
              <span class="d-block">13:00 – 02:00 HRS</span>

              <span class="d-block mt-3 uppercase">{{
                $t("GuadalajaraDays3")
              }}</span>
              <span class="d-block">13:00 – 23:00 HRS</span>
            </v-col>

            <v-col
              cols="12"
              md="4"
              align-self="center"
              class="text-center mt-8 mt-md-0"
            >
              <template v-if="datetime.date.dayOfWeek < 4">
                <!-- Lunes, Martes y Miércoles -->
                <template
                  v-if="datetime.time.hour >= 13 || datetime.time.hour <= 1"
                >
                  <v-card
                    :href="homeSingleton.btn_order_here_2"
                    target="_blank"
                    :ripple="false"
                    elevation="0"
                    color="transparent"
                  >
                    <v-img
                      src="../assets/img/order-here.gif"
                      max-width="215"
                      class="img-open-closed"
                      eager
                    ></v-img>
                  </v-card>
                </template>
                <template v-else>
                  <v-img
                    src="../assets/img/NOWCLOSED-TANAKA.gif"
                    max-width="215"
                    class="img-open-closed"
                    eager
                  ></v-img>
                </template>
              </template>

              <template
                v-else-if="
                  datetime.date.dayOfWeek > 3 && datetime.date.dayOfWeek < 7
                "
              >
                <!-- Jueves, Viernes y sábados -->
                <template
                  v-if="datetime.time.hour >= 13 || datetime.time.hour <= 2"
                >
                  <v-card
                    :href="homeSingleton.btn_order_here_2"
                    target="_blank"
                    :ripple="false"
                    elevation="0"
                    color="transparent"
                  >
                    <v-img
                      src="../assets/img/order-here.gif"
                      max-width="215"
                      class="img-open-closed"
                      eager
                    ></v-img>
                  </v-card>
                </template>
                <template v-else>
                  <v-img
                    src="../assets/img/NOWCLOSED-TANAKA.gif"
                    max-width="215"
                    class="img-open-closed"
                    eager
                  ></v-img>
                </template>
              </template>

              <template v-else>
                <!-- Domingo -->
                <template
                  v-if="datetime.time.hour >= 13 && datetime.time.hour <= 23"
                >
                  <v-card
                    :href="homeSingleton.btn_order_here_2"
                    target="_blank"
                    :ripple="false"
                    elevation="0"
                    color="transparent"
                  >
                    <v-img
                      src="../assets/img/order-here.gif"
                      max-width="215"
                      class="img-open-closed"
                      eager
                    ></v-img>
                  </v-card>
                </template>
                <template v-else>
                  <v-img
                    src="../assets/img/NOWCLOSED-TANAKA.gif"
                    max-width="215"
                    class="img-open-closed"
                    eager
                  ></v-img>
                </template>
              </template>
            </v-col>
          </v-row>
          <v-row justify="center"  v-if="false">
            <v-col cols="auto" md="12" class="mt-3 mt-md-0">
              <v-card
                class="menu-app-item d-inline-block px-4 outlined color-white"
                :href="homeSingleton.btn_delivery_2"
                color="black"
                :ripple="false"
                target="_blank"
                elevation="0"
                tile
                >{{ $t("btnHomeService") }}</v-card
              >
            </v-col>
          </v-row>
          <!-- /sucursal 2 -->

          <v-row no-gutters class="d-none d-md-flex">
            <v-col>
              <hr class="hr-custom d-none d-md-block" />
            </v-col>
          </v-row>

          <!-- sucursal 3 -->
          <v-row class="same-width-hr" justify="center">
            <v-col cols="auto" class="text-center text-md-left">
              <hr class="hr-custom d-md-none" />
              <span class="uppercase d-block">Cancún - {{ $t("ad") }}</span>
            </v-col>
          </v-row>
          <!-- /sucursal 3 -->
        </div>

        <!-- <v-row class="my-2 my-sm-12 pb-12">
          <v-col>
            <a
              href="https://www.opentable.com.mx/senor-tanaka?corrid=a94e6ba0-014f-4cd1-a9ca-767108a71fcb&p=2&sd=2020-06-29%2019%3A00"
              target="_blank"
              class="uppercase btn-reservaciones-home"
              >{{ $t("btnReservations") }}</a
            >
          </v-col>
        </v-row> -->
      </v-container>

      <router-link to="/menu" class="not-text-decoration">
        <v-container
          fluid
          style="position: relative; z-index: 1; background-color: white"
          class="py-0"
        >
          <v-row no-gutters class="fill-height" align="center">
            <v-col class="text-center py-0">
              <span class="black--text titles-app rewrite-font-3"
                >メニュー</span
              >
            </v-col>
          </v-row>
        </v-container>
      </router-link>

      <div
        style="width: 100%; position: fixed; top: 0px; z-index: 0"
        class="d-none"
      >
        <v-container class="py-0">
          <v-row align="center" style="height: 100vh">
            <v-col class="text-center">
              <template v-for="(platillo, p) in platillos">
                <template v-if="hasImage(platillo.imagen)">
                  <transition
                    name="fade-transition"
                    :key="'transition-product-' + p"
                  >
                    <div v-show="activeProduct == p">
                      <v-img
                        :src="
                          'http://srtanakacms.actstudio.xyz/' +
                          platillo.imagen.path
                        "
                        max-width="600px"
                        class="mx-auto"
                        eager
                      ></v-img>
                    </div>
                  </transition>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import carousel from "vue-owl-carousel";

export default {
  name: "Home",
  components: { carousel },
  computed: {
    temporadaOffset: function () {
      var temporada = document.getElementById("temporada");
      // console.log(temporada.scrollTop);
      return temporada.scrollTop;
    },
    contactoOffset: function () {
      return false;
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  data() {
    return {
      renderVideos: false,
      platillos: [],
      destacados: [],
      activeProduct: -1,
      datetime: {},
      videosSingleton: {},
      homeSingleton: {
        btn_order_here:"",
        btn_order_here_2:"",
        btn_delivery:"",
        btn_delivery_2:"",
      },
      showIntro: true,
      carouselTemp: 0,
    };
  },
  watch: {
    lang() {
      if (this.$i18n.locale === "es") {
        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/singletons/get/videos?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
              filter: {
                featured: true,
              },
            }),
          }
        )
          .then((data) => data.json())
          .then((data) => (this.videosSingleton = data))
          .then((data) => (this.renderVideos = true))
          .catch((error) => console.log(error.message));

        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/singletons/get/home?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
          }
        )
          .then((data) => data.json())
          .then((data) => (this.homeSingleton = data))
          .catch((error) => console.log(error.message));

        //fetch para el menú
        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/collections/get/foodDishes?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
              filter: {
                featured: true,
              },
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => (this.platillos = res.entries))
          .catch((error) => console.log(error.message));

        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/collections/get/Destacados?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => (this.destacados = res.entries))
          .catch((error) => console.log(error.message));
      } else if (this.$i18n.locale === "en") {
        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/singletons/get/videos?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
              filter: {
                featured: true,
              },
              lang: "en",
            }),
          }
        )
          .then((data) => data.json())
          .then((data) => (this.videosSingleton = data))
          .then((data) => (this.renderVideos = true))
          .catch((error) => console.log(error.message));

        //fetch para el menú
        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/collections/get/foodDishes?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
              filter: {
                featured: true,
              },
              lang: "en",
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => (this.platillos = res.entries))
          .catch((error) => console.log(error.message));

        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/singletons/get/home?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              lang: "en",
            }),
          }
        )
          .then((data) => data.json())
          .then((data) => (this.homeSingleton = data))
          .catch((error) => console.log(error.message));

        fetch(
          "https://www.srtanakacms.actstudio.xyz/api/collections/get/Destacados?token=1635b46f0568a19ed800dc70231e84",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              populate: 1,
              lang: "en",
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => (this.destacados = res.entries))
          .catch((error) => console.log(error.message));
      } //end elseif
    }, //end lang
    $route: function (from, to) {
      var contacto = document.getElementById("contacto");

      if (this.showIntro == false) {
        if (this.$route.name == "Home") {
          this.$vuetify.goTo(0);
        }
        if (this.$route.name == "Temporada") {
          this.$vuetify.goTo("#temporada");
        }
        if (contacto) {
          if (this.$route.name == "Contacto") {
            this.$vuetify.goTo("#contacto");
          }
        }
      }
    },
  },
  beforeCreate() {
    setTimeout(() => {
      this.showIntro = false;
    }, 7000);

    if (this.$i18n.locale === "es") {
      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/singletons/get/videos?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
            filter: {
              featured: true,
            },
          }),
        }
      )
        .then((data) => data.json())
        .then((data) => (this.videosSingleton = data))
        .then((data) => (this.renderVideos = true))
        .catch((error) => console.log(error.message));

      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/singletons/get/home?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      )
        .then((data) => data.json())
        .then((data) => (this.homeSingleton = data))
        .catch((error) => console.log(error.message));
    } else if (this.$i18n.locale === "en") {
      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/singletons/get/videos?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
            filter: {
              featured: true,
            },
            lang: "en",
          }),
        }
      )
        .then((data) => data.json())
        .then((data) => (this.videosSingleton = data))
        .then((data) => (this.renderVideos = true))
        .catch((error) => console.log(error.message));

      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/singletons/get/home?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            lang: "en",
          }),
        }
      )
        .then((data) => data.json())
        .then((data) => (this.homeSingleton = data))
        .catch((error) => console.log(error.message));
    } //end elseif
  },
  created() {
    Vue.axios
      .get("https://www.srtanaka.actstudio.xyz/obtener_hora.php")
      .then((response) => {
        this.datetime = response.data;
        console.log(response);
      })
      .catch((error) => {
        this.datetime = {
          date: {
            full: "2021-01-06",
            day: "06",
            dayOfWeek: "3",
            month: "01",
            year: "2021",
          },
          time: { full: "16:53", hour: "16", minute: "53" },
        };
      });
  },
  mounted() {
    var contacto = document.getElementById("contacto");

    window.addEventListener("scroll", this.handleScroll);

    var aux = this;
    if (this.showIntro == false) {
      setTimeout(() => {
        if (aux.$route.name == "Temporada") {
          aux.$vuetify.goTo("#temporada");
        }
        if (contacto) {
          if (aux.$route.name == "Contacto") {
            aux.$vuetify.goTo("#contacto");
          }
        }
      }, 1000);
    }

    if (this.$i18n.locale === "es") {
      //fetch para el menú
      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/collections/get/foodDishes?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
            filter: {
              featured: true,
            },
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.platillos = res.entries))
        .catch((error) => console.log(error.message));

      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/collections/get/Destacados?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.destacados = res.entries))
        .catch((error) => console.log(error.message));
    } else if (this.$i18n.locale === "en") {
      //fetch para el menú
      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/collections/get/foodDishes?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
            filter: {
              featured: true,
            },
            lang: "en",
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.platillos = res.entries))
        .catch((error) => console.log(error.message));

      fetch(
        "https://www.srtanakacms.actstudio.xyz/api/collections/get/Destacados?token=1635b46f0568a19ed800dc70231e84",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            populate: 1,
            lang: "en",
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.destacados = res.entries))
        .catch((error) => console.log(error.message));
    } //end elseif

    //evento que emite para cambiar los colores
    this.$emit("update", 0);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    hasImage: function (field) {
      if (field != undefined) {
        return true;
      }
      return false;
    },
    handleScroll(event) {
      var scroll = window.pageYOffset;
      var temporada = document.getElementById("temporada").offsetTop;
      var contacto = document.getElementById("contacto").offsetTop;

      // console.log('scroll: '+scroll)
      // console.log(temporada)
      // console.log(contacto)
      if (scroll < temporada) {
        // console.log(window.location.pathname);
        history.pushState({}, "", "/");
      } else {
        if (scroll < contacto) {
          // console.log(window.location.pathname);
          history.pushState({}, "", "/temporada");
        } else {
          // console.log(window.location.pathname);
          history.pushState({}, "", "/contacto");
        }
      }

      //history.pushState({}, '', '/temporada')
      // Any code to be executed when the window is scrolled
    },
  },
};
</script>