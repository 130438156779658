var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Header"},[(_vm.showAppBar)?[_c('v-app-bar',{staticClass:"header-appbar",class:{
        'background-black': _vm.appBarChange == 0,
        'background-white': _vm.appBarChange == 1,
      },attrs:{"app":"","elevation":"0","color":_vm.colorAppBar,"height":_vm.heightAppBar,"fixed":""}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',[_c('router-link',{staticClass:"not-text-decoration",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"to":"/"}},[_c('span',{staticClass:"titles-app rewrite-font-2"},[_vm._v(" SEÑOR TANAKA 田中氏 ")])])],1),_c('v-col',{staticClass:"d-md-none",attrs:{"cols":"auto","md":"6"},on:{"click":function($event){_vm.open_submenu = !_vm.open_submenu}}},[_c('v-img',{staticClass:"menu-logo",class:{ 'menu-close': _vm.open_submenu },attrs:{"src":_vm.open_submenu ? _vm.menuIconClose : _vm.menuIcon}})],1),(_vm.appBarChange == 0)?[_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"cols":"auto"}},[_c('router-link',{staticClass:"menu-app-item d-inline-block px-4",class:{
                'color-white': _vm.appBarChange == 0,
                'color-black': _vm.appBarChange == 1,
              },attrs:{"to":"/menu"}},[_vm._v(_vm._s(_vm.$t("btnMenu")))])],1),_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"cols":"auto"}},[_c('span',{staticClass:"menu-app-item d-inline-block px-4",class:{
                'color-white': _vm.appBarChange == 0,
                'color-black': _vm.appBarChange == 1,
              },on:{"click":function($event){return _vm.$vuetify.goTo('#contacto')}}},[_vm._v(_vm._s(_vm.$t("btnContact")))])])]:_vm._e(),(_vm.appBarChange == 1)?[_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"cols":"auto"}},[_c('router-link',{staticClass:"menu-app-item d-inline-block px-4",class:{
                'color-white': _vm.appBarChange == 0,
                'color-black': _vm.appBarChange == 1,
              },attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("btnHome")))])],1)]:_vm._e(),_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"cols":"auto"}},[_c('a',{staticClass:"menu-app-item d-inline-block px-4",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"href":"https://www.wabishop.app/srTanaka?menu=efa8b1ca-3c88-4bdd-95c1-c64807603f0e","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("btnHomeService")))])]),_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"cols":"auto"}},[_c('a',{staticClass:"menu-app-item outlined d-inline-block px-4",class:{
              'color-white': _vm.appBarChange == 0,
              'color-black': _vm.appBarChange == 1,
            },attrs:{"href":"https://www.opentable.com.mx/restref/client/?restref=5254&lang=es-MX&ref=10792&fbclid=IwAR2BZUVqFF2PuPmznPeiaMohgb6lWr7I9YESpWrWvK5qnopoPhfa2qdCoLU&corrid=e943a01f-4593-4dd3-bd49-71187f29abde","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("btnReservations")))])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"pl-10"},[_c('span',{staticClass:"active-lang",class:{ 'opacity-custom': this.$i18n.locale == 'en' },on:{"click":function($event){return _vm.setLocale('en')}}},[_vm._v("ENG")]),_c('span',{staticClass:"px-4"},[_vm._v("-")]),_c('span',{staticClass:"active-lang",class:{ 'opacity-custom': this.$i18n.locale == 'es' },on:{"click":function($event){return _vm.setLocale('es')}}},[_vm._v("ESP")])]):_vm._e()],2)],1),_c('subMenu',{attrs:{"show":_vm.open_submenu,"appBarChange":_vm.appBarChange}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }