<template>
  <v-app
    v-bind:class="{
      'background-black': background == 0,
      'background-white': background == 1,
    }"
  >
    <div style="position: relative;">

      <!-- Header -->
      <Header :appBarChange="background"></Header>
      <!-- /Header -->

      <!-- router-view -->
      <v-main>
        <transition name="fade-transition">
          <router-view @update="changeBackground" @updateBar="updateBarM"></router-view>
        </transition>
      </v-main>
      <!-- router-view -->

      <!-- Footer -->
      <Footer></Footer>
      <!-- /Footer -->
    </div>
  </v-app>
</template>

<script>
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    background: 0,
    updateBar: true
  }),
  watch: {
    background: function (value) {
      if (this.background == 0) {
        this.$vuetify.theme.dark = true;
      } else if (this.background == 1) {
        this.$vuetify.theme.light = true;
      }
    },
  },
  methods: {
    changeBackground(x) {
      this.background = x;

      if (this.background == 0) {
        this.$vuetify.theme.dark = true;
      } else if (this.background == 1) {
        this.$vuetify.theme.light = true;
      }
    },
    updateBarM: function (val) {
      this.updateBar = val;
    }
  },
};
</script>
